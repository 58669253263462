<template>
  <div class="hash-block">
    <detailContent
      title="区块详情"
      :datas="dataDetail"
      :labelProp="labelProp"
    ></detailContent>
    <tableList
      :dataList="blockList"
      :mDataList="blockList.slice(0, 5)"
      title="详细记录"
      :columns="blockColumns"
      :mColumns="mBlockColumns"
    >
      <template #hash="{ scoped }">
        <div class="blue text-overflow2" @click="toHashDetail(scoped.row.txId)">
          {{ scoped.row.txId }}
        </div>
      </template>
    </tableList>
  </div>
</template>

<script>
import detailContent from '@/components/data/detailContent'
import tableList from '@/components/data/tableList.vue'
// import mobileTableList from '@/components/data/mobileTableList'
import api from '@/api/services/browser'

export default {
  components: {
    detailContent,
    tableList
  },
  data() {
    return {
      dataDetail: {
        blockHash: '44'
      },
      labelProp: {
        blockHash: '哈希值',
        blockSize: '储存数量',
        blockNumber: '区块高度(NFT)'
      },
      blockColumns: [
        {
          prop: 'blockNumber',
          header: 'table.blockHeight',
          width: '112px'
        },
        {
          prop: 'txId',
          header: 'table.hashValue',
          width: '864px',
          slot: 'hash'
        },
        {
          prop: 'txTime',
          header: 'table.linkTime',
          width: '176px'
        }
      ],
      mBlockColumns: [
        {
          prop: 'blockNumber',
          header: 'table.blockHeight',
          width: '64px'
        },
        {
          prop: 'txId',
          header: 'table.hashValue',
          slot: 'hash'
        },
        {
          prop: 'txTime',
          header: 'table.linkTime',
          width: '88px'
        }
      ],
      blockList: [],
      activeBlockType: '',
      blockHash: ''
    }
  },
  created() {
    const query = this.$route.query

    if (query.type) {
      this.activeBlockType = query.type
    }
    this.blockHash = query.blockHash
    this.getByBlockHash()
  },
  methods: {
    async getByBlockHash() {
      const data = await api.getByBlockHash({
        blockHash: this.blockHash,
        type: this.activeBlockType
      })
      const detail = {
        blockHash: data.blockHash,
        blockNumber: data.blockNumber,
        blockSize: data.blockSize
      }
      this.blockList = data.transferList || []
      this.dataDetail = detail || {}
      console.log(data)
    },
    toHashDetail(hash) {
      this.$router.push(
        `/blockDetail?txId=${hash}&type=${this.activeBlockType}&blockHash=${this.blockHash}`
      )
    }
  }
}
</script>

<style lang="less" scoped>
.hash-block {
}
</style>
